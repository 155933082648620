export type PageWithCTAType = {
	url: string;
	ctas: string[];
	imagePath: string;
	title: string;
	description: string;
	companyDescription: string;
	services: string[];
};

export type PageTheme = {
	primaryColor: string;
	secondaryColor: string;
	accentColor: string;
	logoUrl: string;
};

export type PageWithCTATypeWithTheme = PageWithCTAType & { theme: PageTheme };

export type LogData = {
	message: string;
	summaryForUser?: string;
};

export enum ScrapeResultStatus {
	SUCCESS = 'SUCCESS',
	FAIL = 'FAIL',
	PROCESSING = 'PROCESSING',
	ALL_OK = 'ALL_OK',
}

export type ScrapeResultTriggerData = {
	status: ScrapeResultStatus;
	jobId: string;
	workspaceId?: number;
	url: string;
	logData: LogData;
	result?: PageWithCTATypeWithTheme;
	imageUrl?: string;
};
