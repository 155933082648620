import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from '../RootStore';
import Campaign from './Campaign';
import CampaignApi from '../../api/endpoints/CampaignApi';
import { CampaignDTO, CampaignLeadDTO, CampaignListing } from '../../dto/campaign.types';
import {
	CampaignLeadLogTriggerData,
	CampaignLeadTriggerData,
	CampaignTriggerData,
	Trigger,
	TriggerType,
} from '../../Trigger.types';
import { LogData, ScrapeResultTriggerData } from '../../dto/scraper.types';

export class CampaignStore {
	rootStore: RootStore;

	campaigns: Campaign[] = [];
	listings: CampaignListing[] = [];
	scrapeResults: (ScrapeResultTriggerData & { log: LogData[]; lastUpdated: Date })[] = [];
	isLoadingCampaigns = false;

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, { rootStore: false });
		this.rootStore = rootStore;
		this.processTrigger = this.processTrigger.bind(this);
		this.init();
	}

	init() {
		// initialize if needed
		this.rootStore.userStore.UserIdChanged.on(() => {
			this.destroy();
		});

		this.rootStore.userStore.SignedOut.on(() => {
			this.destroy();
		});
	}

	destroy() {
		// destroy if needed
		this.campaigns = [];
		this.listings = [];
		this.scrapeResults = [];
	}

	processScraperTrigger(trigger: Trigger<ScrapeResultTriggerData>) {
		// process scraper trigger
		console.info('Processing scraper trigger', trigger);
		const data = trigger?.event?.data;
		if (data?.jobId) {
			this.addOrUpdateScrapeResult(data);
		}
	}

	findScrapeResult(jobId: string) {
		return this.scrapeResults.find((r) => r.jobId === jobId);
	}

	processTrigger(trigger: Trigger<CampaignTriggerData | CampaignLeadTriggerData | CampaignLeadLogTriggerData>) {
		try {
			const data = trigger?.event?.data;
			switch (trigger.urn) {
				case TriggerType.CAMPAIGN_CREATED:
				case TriggerType.CAMPAIGN_UPDATED:
				case TriggerType.CAMPAIGN_DELETED:
					this.addOrUpdateCampaign(data as CampaignDTO);
					break;
				case TriggerType.CAMPAIGN_LEAD_CREATED:
				case TriggerType.CAMPAIGN_LEAD_UPDATED:
					this.addOrUpdateLead(data as CampaignLeadDTO);
					break;
				default:
					break;
			}
		} catch (error) {
			console.warn('Failed to process campaign trigger', error, trigger);
		}
	}

	addOrUpdateScrapeResult(result: ScrapeResultTriggerData) {
		if (!result) {
			return;
		}
		try {
			const existingResult = this.scrapeResults.find((r) => r.jobId === result.jobId);
			if (existingResult) {
				if (result.logData) {
					existingResult.log.unshift(result.logData);
				}
				existingResult.result = result.result;
				existingResult.imageUrl = result.imageUrl;
				existingResult.status = result.status;
				existingResult.lastUpdated = new Date();
			} else {
				this.scrapeResults.push({
					...result,
					log: result.logData ? [result.logData] : [],
					lastUpdated: new Date(),
				});
			}
		} catch (error) {
			console.warn('Failed to add or update scrape result', error, result);
		}
	}

	getCampaign(id?: number): Campaign | null {
		if (!id) {
			return null;
		}
		return this.campaigns.find((c) => c.id === id) ?? null;
	}

	async loadListings() {
		const response = await CampaignApi.getListings().catch(() => {
			return { statusCode: 500, data: null };
		});
		if (response.statusCode === 200 && response.data && Array.isArray(response.data?.listings)) {
			this.listings = response.data.listings.map((l) => ({
				...l,
				approved: l.approved ? new Date(l.approved) : null,
				rejected: l.rejected ? new Date(l.rejected) : null,
			}));
		}
	}

	async sendRequest(campaignId: number) {
		const response = await CampaignApi.sendRequest(campaignId);
		if (response.statusCode === 200) {
			this.loadListings().catch((error) => {
				console.error('Failed to load listings', error);
			});
		}
	}

	async withdrawRequest(campaignId: number) {
		const response = await CampaignApi.withdrawRequest(campaignId);
		if (response.statusCode === 200) {
			this.loadListings().catch((error) => {
				console.error('Failed to load listings', error);
			});
		}
	}

	async loadCampaign(id: number) {
		const response = await CampaignApi.getCampaign(id);
		if (response.statusCode === 200) {
			this.addOrUpdateCampaign(response.data);
		}
	}

	async loadCampaigns() {
		if (this.isLoadingCampaigns) {
			return;
		}
		// load campaigns from server
		this.isLoadingCampaigns = true;
		const response = await CampaignApi.getCampaigns().finally(() => {
			runInAction(() => {
				this.isLoadingCampaigns = false;
			});
		});
		if (response.statusCode === 200 && Array.isArray(response.data.campaigns)) {
			this.addOrUpdateCampaigns(response.data.campaigns);
		}
	}

	addOrUpdateCampaigns(campaigns: CampaignDTO[]) {
		try {
			for (const campaign of campaigns) {
				this.addOrUpdateCampaign(campaign);
			}
		} catch (error) {
			console.warn('Failed to add or update campaigns', error, campaigns);
		}
	}

	addOrUpdateCampaign(campaign: CampaignDTO) {
		if (!campaign) {
			return;
		}
		try {
			const existingCampaign = this.campaigns.find((c) => c.id === campaign.id);
			if (existingCampaign) {
				existingCampaign.updateFromDTO(campaign);
			} else {
				this.campaigns.push(new Campaign(campaign));
			}
		} catch (error) {
			console.warn('Failed to add or update campaign', error, campaign);
		}
	}

	addOrUpdateLead(lead: CampaignLeadDTO) {
		if (!lead) {
			return;
		}

		try {
			const campaign = this.campaigns.find((c) => c.id === lead.campaignId);
			if (campaign) {
				campaign.addOrUpdateLead(lead);
			}
		} catch (error) {
			console.warn('Failed to add or update lead', error, lead);
		}
	}
}
