import {
	Button,
	CircularProgress,
	Stack,
	styled,
	StyledComponentProps,
	Link as MuiLink,
	Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import StoreContext from '../../../stores/StoreContext';
import { HookTypes, withHooks } from '../../../utils/withHooks';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import Spotlight from './Spotlight';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import FloatingOrbs from './FloatingOrbs';

import BrowserEmulator from './BrowserEmulator';
import { toast } from 'react-toastify';
import config from '../../../config/config';
import { FetchWrapper } from '../../../fetch/FetchWrapper';
import SmartChatDemoUrlInput from './SmartChatDemoUrlInput';
import { Link } from 'react-router-dom';
import { RootStore } from '../../../stores/RootStore';
import { ScrapeResultStatus } from '../../../dto/scraper.types';

const Grid2Section = styled(Grid2)(({ theme }) => ({
	// padding: theme.spacing(2),
	maxWidth: '1024px',
	paddingBottom: '3rem',
}));

type Props = {
	onWebUrlSubmit?: (url: string, mode: 'desktop' | 'mobile') => void;
	asComponent?: boolean;
	preventNavigation?: boolean;
} & HookTypes &
	StyledComponentProps;

const SmartDemo = observer(
	class SmartDemo extends React.Component<Props> {
		static readonly contextType = StoreContext;
		previewUrl?: string;
		BefareChatId = '488FB28F-A1A1-4725-ACCF-29AF461445E6';
		isLoading = true;
		errorUrl?: string;
		jobId?: string;
		injectedDomain?: string;
		injectedMode?: 'desktop' | 'mobile';

		get params() {
			return this.props.params!;
		}

		get navigate() {
			return this.props.navigate!;
		}

		get location() {
			return this.props.location!;
		}

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				errorUrl: observable,
				previewUrl: observable,
				isLoading: observable,
				jobId: observable,
				injectedDomain: observable,
				injectedMode: observable,
				onWebUrlSubmit: action,
				processNewUrl: action,
				domain: computed,
				mode: computed,
				queryParams: computed,
				scrapeResult: computed,
				mostRecentScrapeResultLog: computed,
				reprocessUrl: action,
			});

			reaction(
				() => this.scrapeResult?.status,
				(result) => {
					if (result) {
						if (result === ScrapeResultStatus.ALL_OK) {
							toast.success('Forhåndsvisning klar');
							this.isLoading = false;
						} else if (result === ScrapeResultStatus.FAIL) {
							toast.error('Kunne ikke generere forhåndsvisning');
							this.SendMessageAndOpen(
								`Det gikk ikke å generere forhåndsvisning av ${this.domain}. Kan dere gi meg en demo og hjelpe meg med å sette opp SmartChat?`
							);
							this.isLoading = false;
							this.errorUrl = this.domain ?? undefined;
						}
					}
				}
			);
		}

		get queryParams() {
			return new URLSearchParams(this.location.search);
		}

		// get domain from query params
		get domain() {
			return this.injectedDomain ?? this.queryParams.get('domain');
		}
		// get mode from query params
		get mode() {
			return this.injectedMode ?? this.queryParams.get('mode') ?? 'desktop';
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get campaignStore() {
			return this.rootStore.campaignStore;
		}

		get scrapeResult() {
			if (!this.jobId) {
				return null;
			}

			return this.campaignStore.findScrapeResult(this.jobId);
		}

		get mostRecentScrapeResultLog() {
			if (!this.scrapeResult) {
				return null;
			}
			return this.scrapeResult.log[0];
		}

		componentDidUpdate(): void {
			this.reprocessUrl();
		}

		reprocessUrl = () => {
			if (this.domain && this.domain !== this.previewUrl) {
				this.processNewUrl();
			}
		};

		componentDidMount() {
			try {
				if ((window as any).BefareChat) {
					(window as any).BefareChat.init(this.BefareChatId);
				}
			} catch (error) {
				console.log('BefareAiChat', error);
			}
		}

		componentWillUnmount(): void {
			try {
				if ((window as any).BefareChat) {
					(window as any).BefareChat.destroy();
				}
				this.processNewUrl();
			} catch (error) {
				console.log('BefareAiChat', error);
			}
		}

		openChat = () => {
			try {
				if ((window as any).BefareChat) {
					(window as any).BefareChat.open();
				}
			} catch (error) {
				console.log('BefareAiChat', error);
			}
		};

		onWebUrlSubmit = (url: string, mode: 'desktop' | 'mobile' = 'desktop') => {
			// we want just the domain, so let's strip the protocol and path using URL constructor
			// if missing https://, we'll add it
			if (!url.startsWith('http')) {
				url = 'https://' + url;
			}
			try {
				if (this.props.onWebUrlSubmit) {
					this.props.onWebUrlSubmit(url, mode);
				}

				const domain = new URL(url).hostname;

				this.previewUrl = undefined;
				// if we are in the same domain, we don't need to navigate
				if (domain === this.domain) {
					this.processNewUrl().catch(console.error);
				} else if (!this.props.preventNavigation) {
					this.navigate(
						`/smart-demo/?domain=${domain}&mode=${mode}${this.rootStore.sessionId ? `&sessionId=${this.rootStore.sessionId}` : ''}`
					);
				} else {
					this.injectedDomain = domain;
					this.injectedMode = mode;
				}
			} catch (error) {
				console.error('Invalid URL', error);
				toast.error('Ugyldig URL');
			}
		};

		processNewUrl = async () => {
			let url = this.domain;
			if (!url) {
				return;
			}
			const mode = this.mode;
			this.isLoading = true;
			if (!url.startsWith('http')) {
				url = 'https://' + url;
			}
			const domain = new URL(url).hostname;
			if (this.previewUrl === domain) {
				toast.info('Henter forhåndsvisning');
				return;
			}

			if (this.errorUrl === domain) {
				toast.warn(`Får ikke til å hente forhåndsvisning av ${domain}`);
				return;
			}

			this.errorUrl = undefined;
			this.previewUrl = undefined;
			const result = await FetchWrapper.get(
				`//${config.hosts.api}/v1/smart-chat/demo?url=${domain}&mode=${mode}${this.rootStore.sessionId ? `&sessionId=${this.rootStore.sessionId}` : ''}`
			).catch((err) => {
				console.warn('Error fetching preview:', err);

				return {
					statusCode: 500,
					data: { jobId: undefined },
				};
			});
			runInAction(() => {
				if (result.statusCode === 200) {
					this.previewUrl = domain;
					this.errorUrl = undefined;
					this.jobId = result.data.jobId;
				} else {
					this.isLoading = false;
					this.previewUrl = undefined;
					this.jobId = undefined;
					this.errorUrl = domain;
					toast.error('Kunne ikke hente forhåndsvisning');
				}
			});
		};

		SendMessageAndOpen(message: string) {
			try {
				if ((window as any).BefareChat) {
					(window as any).BefareChat.sendMessageAndOpen(message);
				}
			} catch (error) {
				console.log('BefareAiChat', error);
			}
		}

		trySmartChat = () => {
			this.SendMessageAndOpen('Hei, jeg er interessert i en demo av SmatChat');
		};

		renderUrlInput() {
			const { asComponent } = this.props;

			return (
				<Grid2Section
					id="smart-demo-input-lad"
					container
					direction="column"
					xs={12}
					justifyContent="center"
					alignItems="center"
					sx={{
						display: 'flex',
						minHeight: asComponent ? 'inherit' : '80vh',
						position: 'relative',
					}}
				>
					<FloatingOrbs numOrbs={5} />
					<SmartChatDemoUrlInput
						onWebUrlSubmit={this.onWebUrlSubmit}
						preventNavigation={this.props.preventNavigation}
					/>
				</Grid2Section>
			);
		}

		renderContent() {
			if (this.isLoading) {
				return (
					<Grid2Section xs={12} justifyContent="center">
						<Stack direction="column" spacing={2} alignItems="center" justifyContent="center">
							<FloatingOrbs numOrbs={5} />
							<CircularProgress />
							<Typography
								variant="h4"
								sx={(theme) => ({
									color: theme.palette.primary.contrastText,
								})}
							>
								Laster {this.domain}...
							</Typography>
							{this.mostRecentScrapeResultLog && (
								<Typography
									variant="body1"
									sx={(theme) => ({
										color: theme.palette.primary.contrastText,
									})}
								>
									{this.mostRecentScrapeResultLog?.summaryForUser ??
										this.mostRecentScrapeResultLog?.message}
								</Typography>
							)}
						</Stack>
					</Grid2Section>
				);
			}

			if (!this.previewUrl) {
				return (
					<Grid2Section
						xs={12}
						justifyContent="center"
						alignItems="center"
						sx={{ display: 'flex', flexDirection: 'column' }}
					>
						<Typography
							variant="h4"
							sx={(theme) => ({ color: theme.palette.primary.contrastText, textAlign: 'center' })}
						>
							Woops!
						</Typography>
						<Typography
							variant="h4"
							sx={(theme) => ({ color: theme.palette.primary.contrastText, textAlign: 'center' })}
						>
							Vi klarte ikke å lage forhåndsvisning
						</Typography>
						<br />
						<Typography
							variant="body1"
							sx={(theme) => ({ color: theme.palette.primary.contrastText, textAlign: 'center' })}
						>
							Det kan være at nettsiden ikke støttes. SmartChat vil fungere likevel, men vi klarer
							dessverre ikke å lage en forhåndsvisning.
						</Typography>
						<br />
						<Typography
							variant="body1"
							sx={(theme) => ({ color: theme.palette.primary.contrastText, textAlign: 'center' })}
						>
							Prøv en annen nettside, eller{' '}
							<MuiLink
								href="/smart-demo"
								sx={(theme) => ({
									color: theme.palette.primary.contrastText,
									textDecoration: 'underline',
								})}
							>
								gå tilbake
							</MuiLink>
						</Typography>
						<br />
						<Button component={Link} to="/smart-demo" variant="outlined">
							Prøv en annen nettside
						</Button>
					</Grid2Section>
				);
			}

			return (
				<>
					<Grid2Section xs={12} alignItems="center" justifyContent="center">
						<BrowserEmulator previewUrl={this.previewUrl} onUrlSubmit={this.onWebUrlSubmit} />
					</Grid2Section>
					<Grid2Section
						xs={12}
						sx={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<Button size="large" onClick={this.trySmartChat} variant="outlined">
							Jeg vil prøve SmartChat på min side
						</Button>
					</Grid2Section>
				</>
			);
		}

		renderDynamicContent() {
			if (this.domain) {
				return (
					<Grid2Section container xs={12} alignItems="center" justifyContent="center">
						{this.renderContent()}
					</Grid2Section>
				);
			}

			return this.renderUrlInput();
		}

		render() {
			const { asComponent } = this.props;

			if (asComponent) {
				return this.renderDynamicContent();
			}

			return (
				<Spotlight>
					<Grid2Section
						container
						xs={12}
						direction="row"
						sx={(theme) => ({
							minHeight: '100vh',
							justifyContent: 'center',
							alignItems: 'center',
							paddingTop: theme.spacing(4),
							padding: { xs: 0, md: theme.spacing(4) },
							margin: 'auto',
						})}
					>
						{this.renderDynamicContent()}
					</Grid2Section>
				</Spotlight>
			);
		}
	}
);

export default withHooks(SmartDemo);
