import {
	alpha,
	AppBar,
	Box,
	Button,
	darken,
	Link as MuiLink,
	Toolbar,
	useScrollTrigger,
	useTheme,
} from '@mui/material';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import LoginButton from '../common/LoginButton';
import ProfilePicture from '../common/ProfilePicture';
import './NavigationBar.scss';
import { AppId } from '../../config/appId.enum';
import config from '../../config/config';
import { HookTypes, withLocation } from '../../utils/withHooks';
import GradientText from '../home/smart/GradientText';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SmartChatLogo from '../logo/SmartChatLogo';
import { AppLogo } from '../logo/AppLogo';

function ElevationScroll(props: {
	children: React.ReactElement;
	color?: 'default' | 'transparent' | 'primary' | 'secondary';
	defaultTextColor?: 'textPrimary' | 'textSecondary' | 'white';
}) {
	const { children, color } = props;
	const theme = useTheme(); // Access the theme

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
	});

	const backgroundColor = trigger
		? alpha(darken(theme.palette.background.default, 0.3), 0.5) // Slightly opaque when triggered
		: alpha(darken(theme.palette.background.default, 0.3), 0.1); // More transparent when not triggered

	let textColor = theme.palette.getContrastText(backgroundColor);

	return React.cloneElement(children, {
		elevation: trigger ? 4 : 0,
		color: trigger ? (color ?? 'default') : 'transparent',
		sx: {
			color: textColor,
			backgroundColor,
			borderRadius: '16px', // Rounded corners when triggered
			// blur filter
			backdropFilter: trigger ? 'blur(10px)' : 'none',
			transition: theme.transitions.create(['background-color', 'color'], {
				duration: theme.transitions.duration.short,
			}), // Smooth transition for color/background changes
			...children.props.sx,
		},
	});
}

type Props = Pick<HookTypes, 'location'>;

const NavigationBar = observer(
	class NavigationBar extends React.Component<Props> {
		static readonly contextType = StoreContext;

		get location() {
			return this.props.location!;
		}

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				workspaceStore: computed,
				companyStore: computed,
				userStore: computed,
				profileStore: computed,
				uiState: computed,
				isHomeScreen: computed,
				isCompanyPage: computed,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get workspaceStore() {
			return this.rootStore.workspaceStore;
		}

		get companyStore() {
			return this.rootStore.companyStore;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get isHomeScreen() {
			const pathname = this.location.pathname;
			return pathname === '/' || pathname === '/home';
		}

		get isCompanyPage() {
			const pathname = this.location.pathname;
			// check if the current page matches /about/<company name>
			return pathname.startsWith('/about/') || pathname.startsWith('/order-chat/');
		}

		smartLinks() {
			const links: any = [
				{ label: 'Forside', to: '/home' },
				{ label: 'Funksjoner', to: '/home#features' },
				{ label: 'Priser', to: '/home#pricing' },
			];

			if (this.userStore.isLoggedIn) {
				links.push({
					label: this.profileStore.isCraftsman ? 'Dashbord' : 'Min side',
					to: '/app/dashboard',
				});
			}

			if (!this.isCompanyPage) {
				links.push({ label: 'Sjekk demo', to: '/smart-demo', type: 'button' });
			}
			return links;
		}

		links() {
			let companiesLinkLabel = `Finn ${this.uiState.entityName}`;
			let companiesLinkColor: 'primary' | 'secondary' = 'primary';

			if (config.appId === AppId.MinElektriker) {
				companiesLinkLabel = 'Chat med elektriker';
				companiesLinkColor = 'secondary';
			}

			const links: any = [
				{ label: 'Forside', to: '/home' },
				{ label: 'Slik fungerer det', to: '/how-it-works' },
				{ label: 'Send forespørsel', to: '/order-chat' },
			];

			if (this.userStore.isLoggedIn) {
				links.push({
					label: this.profileStore.isCraftsman ? 'Dashbord' : 'Min side',
					to: '/app/dashboard',
				});
			}

			if (!this.isCompanyPage) {
				links.push({ label: companiesLinkLabel, to: '/companies', type: 'button', color: companiesLinkColor });
			}

			return links;
		}

		renderLinks() {
			let links = this.links();
			if (config.appId === AppId.Smart) {
				links = this.smartLinks();
			}

			return links.map((link: any, index: number) => {
				if (link.type === 'button') {
					return (
						<Button
							variant="contained"
							color={link.color}
							component={Link}
							key={index}
							to={link.to}
							sx={{
								justifySelf: 'flex-end',
								borderRadius: 16,
								letterSpacing: '1px',
								boxShadow: '0 2px 4px rgba(0,0,0,.26)',
							}}
						>
							{link.label}
						</Button>
					);
				}
				return (
					<MuiLink color="inherit" component={Link} key={index} to={link.to} variant="subtitle1">
						{link.label}
					</MuiLink>
				);
			});
		}

		render() {
			const { isLoggedIn } = this.userStore;
			const { currentUserProfile } = this.profileStore;

			return (
				<ElevationScroll>
					<AppBar
						elevation={1}
						position="sticky"
						className="NavigationBar"
						sx={{
							maxWidth: '1024px',
							left: '50%',
							transform: 'translateX(-50%) translateY(8px)',
							boxSizing: 'border-box',
							position: 'fixed',
						}}
					>
						<Toolbar sx={{ color: 'inherit' }}>
							<MuiLink
								component={Link}
								underline="none"
								to="/"
								className="NavigationBar__logo no-underline"
							>
								<AppLogo darkMode={this.uiState.isDarkMode} />
							</MuiLink>
							<div className="spacer"></div>
							{this.renderLinks()}
							<div className="spacer"></div>

							{isLoggedIn ? (
								<ProfilePicture component={Link} profile={currentUserProfile} to="/dashboard" />
							) : (
								<LoginButton />
							)}
						</Toolbar>
					</AppBar>
				</ElevationScroll>
			);
		}
	}
);

export default withLocation(NavigationBar);
