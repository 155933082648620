import { action, makeAutoObservable, observable, runInAction } from 'mobx';

import { AppId } from '../config/appId.enum';
import LogUtil from '../helpers/LogUtil';
import { Order } from '../types';
import { RootStore } from './RootStore';
import config from '../config/config';
import { throttle } from 'lodash';
import { Project } from './Project';

export enum SoundEffect {
	// eslint-disable-next-line no-unused-vars
	NEW_MESSAGE,
	// eslint-disable-next-line no-unused-vars
	NEW_PROJECT,
	// eslint-disable-next-line no-unused-vars
	NEW_REACTION,
	// eslint-disable-next-line no-unused-vars
	VIDEO_CALL,
	// eslint-disable-next-line no-unused-vars
	BEEP,
	// eslint-disable-next-line no-unused-vars
	PROJECT_ASSIGNED,
	// eslint-disable-next-line no-unused-vars
	PROJECT_COMPLETED,
	// eslint-disable-next-line no-unused-vars
	NEW_AI_MESSAGE,
	// eslint-disable-next-line no-unused-vars
	APP_START,
}

export enum ServiceName {
	// eslint-disable-next-line no-unused-vars
	Befare = 'Befare',
	// eslint-disable-next-line no-unused-vars
	MinElektriker = 'Min Elektriker',
	// eslint-disable-next-line no-unused-vars
	SmartChat = 'SmartChat',
}

declare let window: any;

export class UiState {
	rootStore: RootStore;

	// .struct makes sure observer won't be signaled unless the
	// dimensions object changed in a deepEqual manner.
	windowDimensions = {
		width: window.innerWidth,
		height: window.innerHeight,
	};

	audio: any = {};
	vibrate: any = {};
	muteSounds: boolean = false;

	hasVideoPermission: boolean = false;
	hasAudioPermission: boolean = false;
	requirePermissions: boolean = false;

	isDarkMode: boolean = window.matchMedia('(prefers-color-scheme: dark)');
	workspaceTheme: string = 'Min-Elektriker'; // demo for how to set this up - try "befare"

	isNavigationDrawerOpen: boolean = false;
	chatsDeepCount: number = 1; // TODO Check if there's a better way to do this

	isEditProfileDialogOpen: boolean = false;
	showCreateProjectDialog: boolean = false;
	editProject?: Project;

	currentFilePreviewById: string = '';

	currentChannelId: number | null = null;
	currentProjectId: string | null = null;

	customerSupportChatOpen: boolean = false;

	pendingOrder: Order = {
		description: '',
		address: {
			street: '',
			postCode: '',
			postArea: '',
		},
	};

	playSoundThrottled = throttle(this.playSound, 1000);

	splashScreenOpen: boolean = true;

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, {
			rootStore: false,
			windowDimensions: observable.struct,
			workspaceTheme: observable,
			isNavigationDrawerOpen: observable,
			isEditProfileDialogOpen: observable,
			showCreateProjectDialog: observable,
			isDarkMode: observable,
			setDarkMode: action,
			autoselectTheme: action,
			toggleNavigationDrawer: action,
			toggleEditProfileDialog: action,
			toggleCreateProjectDialog: action,
			entityName: false,
		});

		window.onresize = () => {
			runInAction(() => {
				this.windowDimensions = getWindowDimensions();
			});
		};

		window.playBef = () => {
			this.playSoundThrottled();
		};

		this.rootStore = rootStore;
		this.autoselectTheme();
		this.initPermissions();
		this.initSoundEffects();

		this.listenToSystemDarkModePreference();
	}

	openEditProjectDialog = (project?: Project) => {
		this.editProject = project;
		this.showCreateProjectDialog = true;
	};

	toggleCreateProjectDialog = () => {
		this.showCreateProjectDialog = !this.showCreateProjectDialog;
		this.editProject = undefined;
	};

	toggleCustomerSupportChat = () => {
		this.customerSupportChatOpen = !this.customerSupportChatOpen;
	};

	initSoundEffects() {
		this.audio[SoundEffect.NEW_MESSAGE] = new Audio(`/sounds/hollow-582.mp3`);
		this.audio[SoundEffect.NEW_MESSAGE].load();
		this.vibrate[SoundEffect.NEW_MESSAGE] = [200, 100, 200];

		// todo: replace with new sound
		this.audio[SoundEffect.PROJECT_ASSIGNED] = new Audio(`/sounds/assigned_job.mp3`);
		this.audio[SoundEffect.PROJECT_ASSIGNED].load();
		this.vibrate[SoundEffect.PROJECT_ASSIGNED] = [200, 100, 200];

		this.audio[SoundEffect.PROJECT_COMPLETED] = new Audio(`/sounds/project_complete.mp3`);
		this.audio[SoundEffect.PROJECT_COMPLETED].load();
		this.vibrate[SoundEffect.PROJECT_COMPLETED] = [];
		// todo: replace with new sound
		this.audio[SoundEffect.NEW_AI_MESSAGE] = new Audio(`/sounds/tweet-416.mp3`);
		this.audio[SoundEffect.NEW_AI_MESSAGE].load();
		this.vibrate[SoundEffect.NEW_AI_MESSAGE] = [200, 100, 200];

		this.audio[SoundEffect.NEW_PROJECT] = new Audio(`/sounds/all-cards-on-table-348.mp3`);
		this.audio[SoundEffect.NEW_PROJECT].load();
		this.vibrate[SoundEffect.NEW_PROJECT] = [100, 100, 200, 100, 300];

		this.audio[SoundEffect.NEW_REACTION] = new Audio(`/sounds/tweet-416.mp3`);
		this.audio[SoundEffect.NEW_REACTION].load();
		this.vibrate[SoundEffect.NEW_REACTION] = [50];

		this.audio[SoundEffect.BEEP] = new Audio(`/sounds/tweet-416.mp3`);
		this.audio[SoundEffect.BEEP].load();
		this.vibrate[SoundEffect.BEEP] = [50];

		this.audio[SoundEffect.VIDEO_CALL] = new Audio(`/sounds/ringtone_video.mp3`);
		this.audio[SoundEffect.VIDEO_CALL].load();
		this.vibrate[SoundEffect.VIDEO_CALL] = [500, 250, 500, 250, 500, 250, 500, 250, 500, 250, 500, 250, 500];

		this.audio[SoundEffect.APP_START] = new Audio(`/sounds/system_start.mp3`);
		this.audio[SoundEffect.APP_START].load();
		this.vibrate[SoundEffect.APP_START] = [];
	}

	stopSound(sound: SoundEffect = SoundEffect.NEW_MESSAGE) {
		try {
			if (this.audio[sound]) {
				this.audio[sound].pause();
				if (window.navigator?.vibrate) {
					window.navigator.vibrate(0);
				}
			}
		} catch (err) {
			LogUtil.error(err);
		}
	}

	playSound(sound: SoundEffect = SoundEffect.NEW_MESSAGE) {
		if (!this.muteSounds && this.audio[sound]) {
			try {
				this.audio[sound].volume = 0.1;
				this.audio[sound].play();

				if (this.vibrate[sound] && window.navigator?.vibrate) {
					window.navigator.vibrate(this.vibrate[sound]);
				}
			} catch (err) {
				LogUtil.error(err);
			}
		}
	}

	async initPermissions(requirePermissions: boolean = false) {
		this.requirePermissions = requirePermissions;
		const hasVideoPermission = await hasPermission('videoinput');
		const hasAudioPermission = await hasPermission('audioinput');

		runInAction(() => {
			this.hasVideoPermission = hasVideoPermission;
			this.hasAudioPermission = hasAudioPermission;
		});
	}

	get isLandscapeOrientation() {
		const orientation = (window.screen.orientation || {}).type;

		return (
			orientation === 'landscape-primary' ||
			orientation === 'landscape-secondary' ||
			this.windowDimensions.width > this.windowDimensions.height
		);
	}

	get isValidPendingOrder() {
		return Boolean(
			this.pendingOrder.description &&
			this.pendingOrder.address.street &&
			this.pendingOrder.address.postCode &&
			this.pendingOrder.address.postArea
		);
	}

	get isPortraitOrientation() {
		return !this.isLandscapeOrientation;
	}

	get isMobile() {
		return this.windowDimensions.width < 600;
	}

	get isTablet() {
		return !this.isMobile && !this.isComputer;
	}

	get isComputer() {
		return this.windowDimensions.width >= 1240;
	}

	get isDesktop() {
		return this.windowDimensions.width >= 1440;
	}

	get hasAudiovisualPermissions() {
		return this.hasVideoPermission && this.hasAudioPermission;
	}

	get isEditingProfile() {
		return this.isEditProfileDialogOpen;
	}

	get serviceName() {
		switch (config.appId) {
			case AppId.MinElektriker:
				return ServiceName.MinElektriker;
			case AppId.Smart:
				return ServiceName.SmartChat;
			default:
				return ServiceName.Befare;
		}
	}

	get entityName() {
		switch (config.appId) {
			case AppId.Befare:
				return 'håndverker';
			case AppId.MinElektriker:
				return 'elektriker';
			case AppId.MinRorlegger:
				return 'rørlegger';
			default:
				return 'håndverker';
		}
	}

	toggleNavigationDrawer = () => {
		this.isNavigationDrawerOpen = !this.isNavigationDrawerOpen;
	};

	setDarkMode = (value: boolean) => {
		this.isDarkMode = value;
	};

	toggleEditProfileDialog = () => {
		this.isEditProfileDialogOpen = !this.isEditProfileDialogOpen;
	};

	setCurrentChannelId = (channelId: number | null = null) => {
		this.currentChannelId = channelId;
	};

	setCurrentProjectId = (projectId: string | null = null) => {
		this.currentProjectId = projectId;
	};

	setCurrentFilePreviewIndex = (fileId: string | number) => {
		this.currentFilePreviewById = '' + fileId;
	};

	generateTitleString(title?: string, subTitle?: string, showNotificationCount: boolean = true) {
		let addOn = ``;

		if (subTitle) {
			addOn += ` | ${subTitle}`;
		}
		if (showNotificationCount && this.rootStore.notificationStore.unread > 0) {
			addOn += ` (${this.rootStore.notificationStore.unread})`;
		}
		return `${title ?? this.serviceName}${addOn}`;
	}

	generateDescriptionString(description?: string) {
		if (this.serviceName === ServiceName.SmartChat) {
			return description ?? `Livechat og neste generasjons kundeservice gjort enkelt.`;
		}

		return (
			description ??
			`Med ${this.serviceName} kan du chatte med en faglært elektriker som hjelper deg fra idé til gjennomføring.`
		);
	}

	listenToSystemDarkModePreference() {
		const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
		const handleChange = (event: MediaQueryListEvent) => {
			this.setDarkMode(event.matches);
		};
		mediaQuery.addEventListener('change', handleChange);
	}

	async autoselectTheme() {
		// do this based on domain - that's probably the most robust for now
		switch (config.appId) {
			case AppId.Befare:
				this.workspaceTheme = 'befare';
				this.isDarkMode = true;
				break;
			case AppId.MinElektriker:
				this.workspaceTheme = 'norgeseliten';
				break;
			case AppId.MinRorlegger:
				this.workspaceTheme = 'befare';
				break;
			case AppId.Smart:
				this.workspaceTheme = 'smartchat';
				this.isDarkMode = true;
				break;
			default:
				this.workspaceTheme = 'befare';
		}
	}
}

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return { width, height };
}

export async function hasPermission(kind: string) {
	const devices = await navigator.mediaDevices?.enumerateDevices();
	return devices?.some((device) => device.kind === kind && device.label);
}
