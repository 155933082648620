import { CampaignDTO, CampaignLeadDTO, CampaignLeadLogDTO } from './dto/campaign.types';

// TEMP GENERIC  TRIGGER TYPES
export type PlaceholderTriggerType = {};

// This is a special kind of shitty
export type IdTriggerType = {
	id: string;
};

export type ProjectIdTriggerType = IdTriggerType & {
	projectId: string;
};

export type ChannelIdTriggerType = {
	channelId: string;
};

export type CampaignTriggerData = CampaignDTO;
export type CampaignLeadTriggerData = CampaignLeadDTO;
export type CampaignLeadLogTriggerData = CampaignLeadLogDTO;

// eslint complains about unused types
/* eslint-disable no-unused-vars */
export type TriggerHandlerProps = {
	clientId: string;
	brokers: string[];
};

export type Trigger<T> = {
	urn: string; // eg. nb:befare:trigger:channel:new-message
	workspaceUrn: string; // workspace urn, eg. nb:befare:workspace:10001
	event: TriggerEvent<T>;
};

export type TriggerEvent<T> = {
	timestamp: Date;
	data: T;
	userIds?: string[];
};

export type TriggerVerification = {
	status: TriggerStatus;
	reason?: string;
	recordMetadata?: any[];
};

export enum QuickActionType {
	TEXT = 'text',
	LINK = 'link',
}

export type QuickAction = {
	type: QuickActionType;
	text: string;
	href?: string;
	onLinkClick?: () => void;
	colorOverride?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
};

export type ChannelQuickActionTriggerData = {
	channelId: number;
	quickActions: QuickAction[];
};

export enum TriggerStatus {
	SUCCESS = 1,
	FAIL,
	NOT_IMPLAMENTED,
}

export enum TriggerType {
	// Kafka control signals
	KAFKA_RECONNECT_SUBSCRIBERS = 'nb:befare:trigger:kafka:reconnect',
	// Video chat
	VIDEO_CHAT_STARTED = 'nb:befare:trigger:video-chat:started',
	VIDEO_CHAT_ENDED = 'nb:befare:trigger:video-chat:ended',
	VIDEO_CHAT_JOIN = 'nb:befare:trigger:video-chat:join',
	VIDEO_CHAT_LEAVE = 'nb:befare:trigger:video-chat:leave',
	VIDEO_CHAT_ARCHIVE_STARTED = 'nb:befare:trigger:video-chat:archive:started',
	VIDEO_CHAT_ARCHIVE_ENDED = 'nb:befare:trigger:video-chat:archive:ended',
	VIDEO_CHAT_ARCHIVE_STORED = 'nb:befare:trigger:video-chat:archive:stored',
	// Notification related triggers
	NOTIFICATION_NEW = 'nb:befare:trigger:notification:new',
	NOTIFICATION_CLEARED = 'nb:befare:trigger:notification:cleared',
	NOTIFICATION_SEND_SMS_CUSTOMER_CREATED_NEW_PROJECT = 'nb:befare:trigger:notification:send:sms:project:new:customer',
	NOTIFICATION_SEND_SMS_WORKSPACE_MEMBER_CREATED_NEW_PROJECT = 'nb:befare:trigger:notification:send:sms:project:new:workspace-member',
	NOTIFICATION_SEND_EMAIL_NEW_PROJECT = 'nb:befare:trigger:notification:send:email:project:new',

	NOTIFICATION_SEND_SMS_CUSTOMER_CREATED_NEW_EVENT = 'nb:befare:trigger:notification:send:sms:event:new:customer',
	NOTIFICATION_SEND_SMS_WORKSPACE_MEMBER_CREATED_NEW_EVENT = 'nb:befare:trigger:notification:send:sms:event:new:workspace-member',
	// Project related triggers
	PROJECT_CREATED = 'nb:befare:trigger:project:created',
	PROJECT_UPDATED = 'nb:befare:trigger:project:updated',
	PROJECT_ASSIGNED = 'nb:befare:trigger:project:assigned',
	PROJECT_ASSIGNED_TO_EMPLOYEE = 'nb:befare:trigger:project:assignedtoemployee',
	PROJECT_COMPLETED = 'nb:befare:trigger:project:completed',
	PROJECT_ARCHIVED = 'nb:befare:trigger:project:archived',
	PROJECT_CHANNEL_CREATED = 'nb:befare:trigger:project:channel:created',
	PROJECT_MEMBER_UPDATED = 'nb:befare:trigger:project:member:updated',
	PROJECT_FILE_ADDED = 'nb:befare:trigger:project:file:added',
	PROJECT_FILE_REMOVED = 'nb:befare:trigger:project:file:removed',
	PROJECT_ADDRESS_UPDATED = 'nb:befare:trigger:project:address:updated',
	PROJECT_STATUS_UPDATED = 'nb:befare:trigger:project:status:updated',

	PROJECT_CREATED_ATTACH_FILES_TO_CHANNEL = 'nb:befare:trigger:project:created:attach:file:channel',

	// Chat related triggers
	CHANNEL_MESSAGE_NEW = 'nb:befare:trigger:channel:message:new',
	CHANNEL_MESSAGE_SEEN = 'nb:befare:trigger:channel:message:seen',
	CHANNEL_MESSAGE_DELETED = 'nb:befare:trigger:channel:message:deleted',
	CHANNEL_MESSAGE_UPDATED = 'nb:befare:trigger:channel:message:updated',
	CHANNEL_REACTION_NEW = 'nb:befare:trigger:channel:message:reaction:new',
	CHANNEL_REACTION_DELETED = 'nb:befare:trigger:channel:message:reaction:deleted',
	CHANNEL_REACTION_UPDATED = 'nb:befare:trigger:channel:message:reaction:updated',
	CHANNEL_THREAD_MESSAGE_NEW = 'nb:befare:trigger:channel:thread:message:new',
	CHANNEL_JOIN = 'nb:befare:trigger:channel:join',
	CHANNEL_LEAVE = 'nb:befare:trigger:channel:leave',
	CHANNEL_TYPING = 'nb:befare:trigger:channel:typing',
	CHANNEL_PEEKING = 'nb:befare:trigger:channel:peeking',
	CHANNEL_CREATED = 'nb:befare:trigger:channel:created',
	CHANNEL_UPDATED = 'nb:befare:trigger:channel:updated',
	CHANNEL_DELETED = 'nb:befare:trigger:channel:deleted',
	CHANNEL_WORKSPACE_ACCESS_ADDED = 'nb:befare:trigger:channel:workspace:access:added',
	CHANNEL_WORKSPACE_ACCESS_REMOVED = 'nb:befare:trigger:channel:workspace:access:removed',
	CHANNEL_DYNAMIC_VIEW = 'nb:befare:trigger:channel:dynamic:view',
	// Channel quick actions
	CHANNEL_QUICK_ACTION = 'nb:befare:trigger:channel:quick-action',
	CHANNEL_QUICK_ACTION_CREATED = 'nb:befare:trigger:channel:quick-action:created',
	CHANNEL_QUICK_ACTION_CLICKED = 'nb:befare:trigger:channel:quick-action:clicked',

	// Notifications/websocket
	WEBSOCKET_RELAY = 'nb:befare:trigger:websocket',
	NOTIFY_RELAY = 'nb:befare:trigger:notify',

	// User
	USER_ONLINE = 'nb:befare:trigger:user:online',

	// PROFILE
	PROFILE_UPDATED = 'nb:befare:trigger:profile:updated',

	// EVENTS
	EVENT_CREATED = 'nb:befare:trigger:event:created',
	EVENT_UPDATED = 'nb:befare:trigger:event:updated',
	EVENT_DELETED = 'nb:befare:trigger:event:deleted',

	// TRAK
	TRAK_EVENT_CREATED = 'nb:befare:trigger:trak:event:created',
	TRAK_EVENT_UPDATED = 'nb:befare:trigger:trak:event:updated',

	// DOCUMENT_SIGNING
	DOCUMENT_SIGNING_DOCUMENT_CREATED = 'nb:befare:trigger:document_signing:document:created',
	DOCUMENT_SIGNING_DOCUMENT_UPDATED = 'nb:befare:trigger:document_signing:document:updated',
	DOCUMENT_SIGNING_SIGNER_UPDATED = 'nb:befare:trigger:document_signing:signer:updated',
	DOCUMENT_SIGNING_LOG_CREATED = 'nb:befare:trigger:document_signing:log:created',

	// Customer service
	CUSTOMER_SERVICE_CHANNEL_CREATED = 'nb:befare:trigger:customer_service:channel:created',
	CUSTOMER_SERVICE_CHANNEL_UPDATED = 'nb:befare:trigger:customer_service:channel:updated',
	CUSTOMER_SERVICE_CHANNEL_DELETED = 'nb:befare:trigger:customer_service:channel:deleted',
	CUSTOMER_SERVICE_CUSTOMER_MESSAGE_CREATED = 'nb:befare:trigger:customer_service:customer:message:created',
	CUSTOMER_SERVICE_MESSAGE_CREATED = 'nb:befare:trigger:customer_service:message:created',
	CUSTOMER_SERVICE_PROJECT_CREATED = 'nb:befare:trigger:customer_service:project:created',
	CUSTOMER_SERVICE_PROJECT_UPDATED = 'nb:befare:trigger:customer_service:project:updated',
	CUSTOMER_SERVICE_PROJECT_DELETED = 'nb:befare:trigger:customer_service:project:deleted',
	CUSTOMER_SERVICE_PROJECT_CREATED_BY_CUSTOMER = 'nb:befare:trigger:customer_service:project:created:by:customer',

	// Incoming email
	INCOMING_EMAIL_CREATED = 'nb:befare:trigger:incoming_email:created',

	// Kobbr
	KOBBR_QUOTE_CREATED = 'nb:befare:trigger:kobbr:quote:created',
	KOBBR_QUOTE_UPDATED = 'nb:befare:trigger:kobbr:quote:updated',

	// Assistant
	ASSISTANT_THREAD_MESSAGE_CREATED = 'nb:befare:trigger:assistant:thread:message:created',
	ASSISTANT_THREAD_MESSAGE_UPDATED = 'nb:befare:trigger:assistant:thread:message:updated',
	ASSISTANT_THREAD_MESSAGE_DELETED = 'nb:befare:trigger:assistant:thread:message:deleted',
	ASSISTANT_THREAD_CREATED = 'nb:befare:trigger:assistant:thread:created',
	ASSISTANT_THREAD_UPDATED = 'nb:befare:trigger:assistant:thread:updated',
	ASSISTANT_THREAD_DELETED = 'nb:befare:trigger:assistant:thread:deleted',

	// Partnership
	PARTNERSHIP_REQUEST_CREATED = 'nb:befare:trigger:partnership:request:created',
	PARTNERSHIP_REQUEST_UPDATED = 'nb:befare:trigger:partnership:request:updated',
	PARTNERSHIP_REQUEST_DELETED = 'nb:befare:trigger:partnership:request:deleted',
	PARTNERSHIP_CREATED = 'nb:befare:trigger:partnership:created',
	PARTNERSHIP_UPDATED = 'nb:befare:trigger:partnership:updated',
	PARTNERSHIP_DELETED = 'nb:befare:trigger:partnership:deleted',
	PARTNERSHIP_REQUEST_FROM_LISTING = 'nb:befare:trigger:partnership:request:from:listing',
	PARTNERSHIP_REQUEST_FROM_LISTING_CREATED = 'nb:befare:trigger:partnership:request:from:listing:created',
	PARTNERSHIP_REQUEST_FROM_LISTING_UPDATED = 'nb:befare:trigger:partnership:request:from:listing:updated',
	PARTNERSHIP_REQUEST_FROM_LISTING_DELETED = 'nb:befare:trigger:partnership:request:from:listing:deleted',
	PARTNERSHIP_REQUEST_FROM_LISTING_ACCEPTED = 'nb:befare:trigger:partnership:request:from:listing:accepted',
	PARTNERSHIP_PREMIUM_LISTING_CREATED = 'nb:befare:trigger:partnership:premium:listing:created',
	PARTNERSHIP_PREMIUM_LISTING_UPDATED = 'nb:befare:trigger:partnership:premium:listing:updated',
	PARTNERSHIP_PREMIUM_LISTING_DELETED = 'nb:befare:trigger:partnership:premium:listing:deleted',

	// Campaign
	CAMPAIGN_CREATED = 'nb:befare:trigger:campaign:created',
	CAMPAIGN_UPDATED = 'nb:befare:trigger:campaign:updated',
	CAMPAIGN_DELETED = 'nb:befare:trigger:campaign:deleted',
	CAMPAIGN_LEAD_CREATED = 'nb:befare:trigger:campaign:lead:created',
	CAMPAIGN_LEAD_UPDATED = 'nb:befare:trigger:campaign:lead:updated',
	CAMPAIGN_LEAD_DELETED = 'nb:befare:trigger:campaign:lead:deleted',
	CAMPAIGN_LEAD_RESOLVED = 'nb:befare:trigger:campaign:lead:resolved',
	CAMPAIGN_LEAD_CONVERTED = 'nb:befare:trigger:campaign:lead:converted',
	CAMPAIGN_LEAD_LOG_CREATED = 'nb:befare:trigger:campaign:lead:log:created',
}
/* eslint-enable no-unused-vars */
